/* bootstrap override */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 961px,
  xl: 1200px
);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: 4px,
    2: 8px,
    3: 18px,
    4: 30px,
    5: 48px
  ),
  $spacers
);

$body-bg: #ececec;
$body-color: #242424;
$primary: #000;
$red : #ff0000;
$text-muted: #999;

$grid-gutter-width: 18px;

/* custom vars */
$text-black: #242424;
$border-gray: #e9e9e9;
$hover-gray: #f2f2f2;

$sidebar-w: 88px;

$content-min-w: 990px;
$content-max-w: 1410px;
$content-sm-pd-x: 16px;

$navbar-h: 60px;
$navbar-sm-h: 48px;

$checkbox-custom-size: 16px;
