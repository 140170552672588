@import 'variables.scss';
@import 'modal.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-datepicker/src/stylesheets/datepicker.scss';
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

// FIX for this issue.
// https://github.com/puppeteer/puppeteer/issues/3834#issuecomment-460297237
@page {
    size: auto;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
}

/* Fonts */

@font-face {
    font-family: 'NotoSansCJKjp';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/NotoSansCJKjp-Regular.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'NotoSansCJKjp';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/NotoSansCJKjp-Medium.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'NotoSansCJKjp';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/NotoSansCJKjp-Bold.woff') format('woff');
    font-display: swap;

}
@font-face {
    font-family: 'NotoSansCJKjp';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/NotoSansCJKjp-Black.woff') format('woff');
    font-display: swap;
}

/* Mixin */

@mixin bg-pattern(){
    background-image: linear-gradient(to bottom, transparent 50%, #fff 50%),
                        linear-gradient(to right, #e4e4e4 50%,  #fff 50%);
    background-size: 2px 2px, 2px 2px;
    background-color: #fff;
}

@mixin bg-white($p-x: 30px, $p-y: 30px){
    background-color: #fff;
    padding-top: $p-y;
    padding-bottom: $p-y;
    padding-left: $p-x;
    padding-right: $p-x;
}

@mixin arrow-border($size: 1rem, $color: #989898, $rotate: -45deg){
    display: inline-block;
    width: $size;
    height: $size;
    border-top: 1px solid $color;
    border-left: 1px solid $color;
    transform: rotate($rotate);
}

@mixin lhCrop($line-height) { // for Noto Sans
    &::before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        margin-top: calc((1 - #{$line-height}) * 0.5em);
    }
}

@mixin pHeight($fontsize, $line-height, $length) {
    max-height: calc(#{$fontsize} * #{$line-height} * #{$length} * 1px);
    overflow: hidden;
}

@mixin hideScrollBar{
    // スクロールバーの非表示
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
}

/* Override Bootstrap */

:root{
    font-size: 14px;
}

body{
    font-family: 'NotoSansCJKjp', "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, sans-serif;
}

ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

a{
    color: $text-black;
    transition: all 200ms;
    .icon{
        transition: all 200ms;
    }
    &:hover{
        text-decoration: none;
        color: rgba($text-black, 0.6);
        .icon{
            opacity: 0.6;
        }
    }
}

.text-secondary{
    color: #666 !important;
}

.text-black{
    color: $text-black;
}

.text-light-gray{
    color: #9a9a9a !important;
}

button, .btn{
    &:focus{
        outline: none;
    }
}

.btn{
    line-height: 1;
    padding: 9px 1.142rem;
    font-size: 13px;
    border-radius: 4px;
    transition: all 200ms;
    white-space: nowrap;
    font-weight: 500;
    
    &.has-icon{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & > .icon{
        transition: all 200ms;
    }

    & > .icon:last-child{
        margin-left: 10px;
    }

    & .icon-plus.icon-left {
        position: absolute;
        left: 14px;
        top: calc(50% - 3px);
    }
    
    &.is-no-hover:hover{
        cursor: auto;
    }

    &:disabled, &[disabled], .disabled{
        opacity: 0.4;
        cursor: default;

        &:hover{
            background-color: inherit !important;
            color: inherit !important;
            border-color: $border-gray;
        }
    }

    &.icon-fix{
        position: relative;
        .icon{
            position: absolute;
            margin-left: 0;
            left: 12px;
        }
    }

    &:hover{
        .icon{
            opacity: 1;
        }
    }
}

.btn-primary{
    color: #FFF;

    &:hover{
        background-color: rgba($primary, 0.7);
        border-color: transparent;
    }
}

.btn-light{
    background-color: #fff;
    &:hover{
        background-color: #fff;
        border-color: #FFF;
        color: rgba(0, 0, 0, 0.6);
        .icon{
            opacity: 0.6;
        }
    }
}

.btn-lg{
    font-size: 1.142rem;
    padding: 16px;
}

.btn-xl{
    font-size: 1.142rem;
    padding: 24px 16px;
    .icon{
        height: 20px;
        margin-right: 12px;
    }
}

.btn-border{
    border-color: #d6d6d6;
    &:hover:not(.is-no-hover){
        border-color: #000;
        background-color: #000;
        color: #FFF;
    }

    &.dropdown-toggle:hover::after{
        border-color: #FFF transparent transparent transparent;
    }

    .icon{
        mix-blend-mode: difference;
    }
}

.btn-lined-white{
    border: 1px solid #FFF;
    color: #FFF;
    font-size: 12px;
    .arrow::after{
        content: '';
        @include arrow-border(6px, #FFF, 135deg);
        position: absolute;
        left: 10px;
        top: calc(50% - 3px);
    }

    .icon-plus{
        position: absolute;
        left: 12px;
        top: calc(50% - 3px);
        margin-left: 0 !important;
    }

    &:hover{
        color: #FFF;
    }
}

button.close{
    outline: 0;
}

.form-group{

    &:last-child{
        margin-bottom: 0;
    }

    &.disabled{
        opacity: 0.4;
    }

    label:not(.form-check-label){
        font-weight: bold;
        color: #333;
        margin-bottom: 20px;
        line-height: 1;
    }
}

.form-control, .btn{
    &:focus{
        box-shadow: none;
    }
}
.form-control{
    border-color: #d6d6d6;
    height: 40px;
    color: #333;

    &.form-control-md{
        height: 32px;
        font-size: 12px;
    }
}

.form-check-inline{
    margin-bottom: 14px;
    margin-right: 18px;
    .form-check-input{
        margin-right: 8px;
    }
}

textarea.form-control{
    line-height: 1.7;
}

.main-content:not(.only-wide){
    @include media-breakpoint-down(md){
        input[type="text"]{
            font-size: 16px;
        } 
    }
}

.invalid-feedback{
    display: block;
    font-weight: bold;
    margin-top: 20px;
}

.was-validated .form-control:invalid,.form-control.is-invalid{
    background-image: none;
    background-color: #fff9f9;
    &.form-control{
        padding-right: 0.75rem;
    }
}

.was-validated .form-control:valid, .form-control.is-valid{
    background-image: none;
    background-color: rgba($success, 0.1);
    &.form-control{
        padding-right: 0.75rem;
    }
}

.table{
    th, td{
        padding: 18px 20px;
        border-left: 1px solid $border-gray;
        transition: all 200ms;

        &:first-child{
            border-left: none;
        }

        &.td-date{
            width: 118px;
            text-align: center;
        }
        &.td-publish{
            width: 90px;
            text-align: center;
        }
        &.td-pickup{
            width: 126px;
            text-align: center;
        }

        &.no-border-x{
            border-left: none;
        }
    }

    thead.bg-pattern{
        th, td{
            border-bottom-width: 1px;
            height: 76px;
            vertical-align: middle;
        }
    }

    tbody tr:hover{
        th, td{
            background-color: $hover-gray;
            color: rgba($text-black, 0.6);
        }
    }
}

/* TO DO どこにも使わなかったら削除
.input-group{
    height: 40px;
    &.has-icon{
        display: flex;
        .form-control{
            height: auto;
            padding-left: 0;
            border-left: none;
        }
        .form-control:focus{
            border-color: #d6d6d6;
        }
        button:focus{
            outline: none;
        }    
    }
}
.input-group-text{
    background-color: transparent;
}
*/

.form-check{
    padding-left: 1.5rem;
    vertical-align: middle;

    .form-check-input{
        position: absolute;
        opacity: 0;
        z-index: 1;
        top: 0;
        cursor: pointer;
        & + label{
            position: relative;
            line-height: 1.1;
            cursor: pointer;
            transition: all 200ms;
        }
        & + label::before{
            content: "";
            display: inline-block;
            position: absolute;
            width: 1rem;
            height: 1rem;
            left: 0;
            margin-left: -1.5rem;
            border: 1px solid #c1c1c1;
            border-radius: 2px;
            background-color: #fff;
            transition: all 200ms;
        }
        &:hover + label{
            color: rgba($text-black, 0.6);
            &::before{
                background-color: $hover-gray;
            }
        }

        &:checked + label{
            &::before {
                background-color: $primary;
                border-color: $primary;
            }
            &::after{
                content: "";
                display: inline-block;
                width: 1rem;
                height: 1rem;
                left: -1.5rem;
                background: url("../images/checked.svg") center 50% no-repeat;
                background-size: 10px 6px;
                position: absolute;
                
            }
        }
    }

    label{
        font-size: 13px;
    }

    .input-textless{
        left: 0;
        margin-right: 0;
        
        & + label{
            min-height: 10px;

            &::before{
                margin-left: -19px;
            }
        }
    }

    input[type="radio"] + label::before{
        border-radius: 50%;
    }

    input.is-border[type="checkbox"]{
        width: $checkbox-custom-size;
        height: $checkbox-custom-size;
        & + label{
            &::before{
                border-width: 2px;
                height: 1rem;
                border-radius: 0;
                width: $checkbox-custom-size;
                height: $checkbox-custom-size;
            }
            &::after{
                background-size: 12px 8px;
                width: $checkbox-custom-size;
                height: $checkbox-custom-size;
                left: -19px;
            }
        }
    }
    input.is-border[type="checkbox"]{
        &:not(:checked) + label::before{
            border-color:#bbbbbb;
        }
        &:hover:not(:checked) + label::before{
            background-color: #ececec;
        }
    }
    &.checkbox-all{
        position: relative;

        input.is-border[type="checkbox"]:not(:checked) + label::before{
            border-color: #767676;
        }
        input.is-border[type="checkbox"]:checked + label::after{
            background-image: linear-gradient(#FFF 0, #FFF 100%);
            background-size: 8px 2px;
            background-position: 4px 50%;
        }
    }
}

.card{
    border: 0;
}

.card, .card-img, .card-img-top, .card-footer:last-child{
    border-radius: 0;
    overflow: hidden;

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: transform 300ms;
    }
}

.col-lg-4{
    @include media-breakpoint-up(lg){
        min-width: 318px;
    }
}

.dropdown{
    &.show{
        box-shadow: 1.414px 1.414px 3.92px 0.08px rgba(0, 0, 0, 0.1);
        .dropdown-toggle::after{
            transform: rotate(180deg);
        }
    }
}

.dropdown-toggle{
    &:not(.single-caret){
        width: 100%;
        justify-content: space-between;
    }
}
.dropdown-toggle::after{
    transition: transform 200ms;
    border-width: 8px 3px 0 3px;
    border-color: #333333 transparent transparent transparent;
    margin-left: 10px;
    transform: translateY(3px);
}

.dropdown-menu{
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    background-clip: unset;
    border: none;
    border-top: 1px solid #e9e9e9;
    box-shadow: 1.414px 1.414px 3.92px 0.08px rgba(0, 0, 0, 0.1);
    top: auto;
    max-height: calc(100vh - #{$navbar-h});
    overflow-y: auto;
    @include media-breakpoint-down(lg){
        max-height: calc(100vh - (#{$navbar-sm-h} + 80px));
    }
}

.dropdown-item, .dropdown-form-item{
    padding: 10px 20px;
    font-size: 12px;
    label{
        width: 100%;
    }
}

.lead{
    font-size: 1.428rem;
    font-weight: 500;
}

.page-item:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-item:nth-child(2) .page-link{
    border-left: none;
}

.page-link{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 60px;
    width: 60px;
    border: none;
    border-left: 1px solid $border-gray;
    transition: all 200ms;
    margin-left: 0;

    &:hover{
        background-color: #000;
        color: #FFF;
    }

    &.page-nav{
        background: transparent;
        display: flex;
        align-items: center;
        width: auto;
        border: none;
        box-shadow: none;

        &:hover{
            opacity: 0.6;
        }
    }

    .arrow{
        display: inline-block;
        width: 24px;
        height: 24px;
        border-top: 1px solid #989898;
        border-left: 1px solid #989898;
        transform: rotate(-45deg);
    }

    .arrow-next{
        transform: rotate(135deg);
    }
}

//SP .page-link

.main-content:not(.only-wide){
    @include media-breakpoint-down(md){
        .page-link{
            height: 48px;
            width: 48px;
            font-size: 12px;

            .arrow{
                width: 20px;
                height: 20px;
            }
    
            .arrow-prev{
                margin-right: -14px;
            }
    
            .arrow-next{
                transform: rotate(135deg);
                margin-left: -14px;
            }        
        }
    }
}

.nav-link{
    transition: all 200ms;
}

.modal-dialog{
    &:focus{
        outline: 0;
    }

    @include media-breakpoint-down(md){
        min-width: calc(100% - 32px);
    }

    button.close{
        opacity: 1;
        transition: opacity 200ms;
        &.close:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):focus{
            outline: none;
            opacity: 0.5;
        }

        .icon{
            width: 18px;
            height: 18px;
        }
    }

}

.modal-content{
    border-radius: 0;
    border: none;
    background-clip: unset;
}

.modal-header{
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-bottom: 0;

    position: relative;

    .btn-submit {
        position: absolute;
        right: 100px;
        top: 30px;
    }

    .close{
        opacity: 1;
    }
}

.modal-footer{
    border-top: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &:empty, &.empty{
        height: 30px;
    }
}

.modal-text{
    padding: 0 60px;
    line-height: 1.5;
    @include media-breakpoint-down(md){
        padding: 0;
        font-size: 16px
    }
}

.Overlay{
    z-index: 2000;
}

@include media-breakpoint-up(lg){
    .modal-body{
        padding: 20px;
    }
    .modal-header{
        padding: 20px 20px 0;
    }
}

.modal-dialog-lg{
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: auto;
    right: auto;
    outline: none;
    padding-right: 60px;
    padding-left: 60px;
    max-width: calc(1282px + 120px);
    width: 100%;

    .modal-content{
        min-width: 1030px;
        overflow: auto;
        height: 100%;
    }

    .modal-header{
        display: flex;
        padding: 36px;
        min-height: 118px;
    
        .icon-close-dark{
            width: 18px;
            height: 18px;
        }

        .form-group-search{
            width: 336px;
        }
    }

    .nav-search{
        .form-section{
            padding: 36px;
            border: none;
        }

        .form-group.d-flex .form-check{
            margin-top: 0;
        }

        .form-check label{
            font-size: 13px;
        }

        .title.has-bullet{
            line-height: 1;
        }
    }

    .section-no-item{
        min-height: 300px;
    }

    .react-datepicker-wrapper .form-control:hover{
        background-position: 13px -16px;
    }

}

.modal-title-lg{
    margin-bottom: 0;
    img{
        height: 46px;
    }
}

.modal-body-gray{
    padding: 36px;
    background-color: $body-bg;
}

.modal-footer-gray{
    background-color: $body-bg;
}


/* Icons */

.icon{
    display: inline-block;
    background-size: contain;
    padding: 0;

    img{
        height: 100%;
    }
}

.icon-outlink{
    width: 9px;
    height: 9px;
    background-image: url("../images/icon_outlink.svg");
    background-position: left top;
    background-repeat: no-repeat;
}

.icon-trash{
    height: 16px;
    width: 13px;
    background-image: url("../images/icon_trash.svg");
    background-position: left top;
    background-repeat: no-repeat;
}

.icon-checked-circle{
    height: 16px;
    width: 16px;
    background-image: url("../images/icon_checked_circle.svg");
    background-position: left top;
    background-repeat: no-repeat;
}

.icon-remove-circle{
    height: 16px;
    width: 16px;
    background-image: url("../images/icon_remove_circle.svg");
    background-position: left top;
    background-repeat: no-repeat;
}

.icon-close{
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("../images/icon_close.svg") left top no-repeat;
    background-size: cover;
}


.icon-close-delete{
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url("../images/icon_close_delete.svg") left top no-repeat;
    background-size: cover;
}

.icon-close-dark{
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("../images/icon_close_dark.svg") left top no-repeat;
    background-size: cover;
}

.icon-close-dark-sm{
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url("../images/icon_close_dark_sm.svg") left top no-repeat;
    background-size: cover;
}

.icon-slide-add{
    width: 13px;
    height: 13px;
    background: url("../images/icon_slide_add.svg") left top no-repeat;
}

.icon-slide-delete{
    width: 13px;
    height: 13px;
    background: url("../images/icon_slide_delete.svg") left top no-repeat;
}

.icon-works-add{
    width: 22px;
    height: 22px;
    background-image: url("../images/icon_works_add.svg");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: unset !important;
    transition: unset !important;
}

.btn-border:hover .icon-works-add{
    background-position: -22px top;
}

.icon-edit{
    width: 16px;
    height: 16px;
    background: url("../images/icon_edit.svg") left top no-repeat;
}

.icon-search::before{
    content: "";
    display: inline-block;
    background-image: url('../images/icon_search.svg');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}


.icon-plus{
    &::before, &::after{
        content: "";
        display: block;
        width: 2px;
        height: 8px;
        background-color: #ffffff;
    }

    &::after{
        transform: rotate(90deg) translatex(-8px);
    }
}

/* Override React Modules */

// Date Picker

.react-datepicker{
    border-color: #D6D6D6;
    font-family: 'NotoSansCJKjp';
}

.react-datepicker__input-container .form-control{
    /*display: flex;
    align-items: center;*/

    &:hover{
        background-color: #ececec;
        color: rgba(0, 0, 0, 0.6);
    }
}

.react-datepicker__placeholder_text, .react-datepicker__current_text{
    margin-top: 3.5px;
    display: block;
}

.react-datepicker-popper{
    z-index: 30;
}

.react-datepicker__header{
    border-color: #D6D6D6;
}

.react-datepicker__current-month{
    font-weight: 500;
}

.react-datepicker-wrapper{
    width: 100%;
    .form-control{
        padding-left: 36px;
        height: 40px;
        background: url("../images/icon_calendar.svg") 13px 13px no-repeat;
        background-size: 13px auto;

        &.is-small{
            height: 32px;
            background-position: 13px 10px;
        }

        &:hover{
            background-position: 13px -13px;
        }
    }
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover{
    border-radius: 0;
    background-color: #ECECEC;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected{
    background-color: $primary;
    border-radius: 0;
    &:hover{
        background-color: $primary;
    }
}

.react-datepicker__close-icon::after{
    background-color: $primary;
}

// Alert
.alertOverlay{
    background-color: transparent;

    .modal-dialog {
        min-width: auto !important;
    }

    .modal-content{
        color: #FFF;
        font-size: 1rem;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.75);
    }
}

/* Override Browser Style */

.editable{
    overflow-wrap: break-word;
    min-width: 1em;
    min-height: 1em;

    &:empty, &.empty{
        min-width: 100%;
        min-height: 1em;
    }

    &:focus, &:empty, &.empty{
        outline: solid 1px #CCC;
        outline-offset: -2px;
    }
}

// for Chrome
input:-internal-autofill-selected{
    background-color: #FFFFFF;
}

div[tabindex="0"]:focus{
    outline: none;
}

// Slider

.slick-slider{
    padding-bottom: 64px;

    @include media-breakpoint-down(md){
        padding-bottom: 45px;
        overflow: hidden;
        margin-left: -1px;
    }
}

.slick-dots{
    bottom: 12px;
    @include media-breakpoint-down(md){
        bottom: 7px;
    }
}

.slick-dots li{
    outline: none;
    border: solid 1px #808080;
    margin: 0 3px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    overflow: visible;

    &.slick-active, &:hover{
        background-color: #808080;
        box-shadow: 0 0 0 1px $body-bg inset;
    }

    @include media-breakpoint-up(lg){
        width: 9px;
        height: 9px;  
    }

    button{
        width: 100%;
        height: 100%;
        &::before{
            display: none;
        }
    }
}

.slick-arrow{
    opacity: 1;
    top: auto;
    bottom: 0;
    width: 20px;
    height: 38px;
    transform: none;
    z-index: 10;

    &:hover{
        background: transparent;
        opacity: 0.6;
    }

    @include media-breakpoint-down(md){
        bottom: -5px;
    }
}

.slick-arrow::before{
    font-family: sans-serif;
    @include arrow-border(24px, #808080, -45deg);
    content: "";

    @include media-breakpoint-down(md){
        @include arrow-border(20px, #808080, -45deg);
    }
}

.slick-prev{
    left: calc(50% - 56px);
}

.slick-next{
    right: calc(50% - 54px);
}

.slick-next::before{
    transform: rotate(135deg);
}

.slick-track{
    display: flex;
}

.slick-slide{
    position: relative;
    //z-index: 10;
    float: none;
    height: auto;

    & > div{
        height: 100%;
    }

    .card-list-item{
        height: 100%;
        margin-bottom: 0;
    }
}

.slide-list-item{
    margin: 0 -9px;

    .slide-row-wrapper{
        padding: 0 9px;
    }

    .slick-slide{
        overflow-x: auto;
        overflow-y: hidden;    
    }

    .slide-item{
        height: 100%;
        display: block !important;
        outline: none;
    }

    @include media-breakpoint-down(md){
        margin-right: -16px;

        .slick-slide{
            min-width: 240px;
        }
        
        .slick-track, .slick-list{
            min-width: 500px;
        }

        .slick-prev{
            left: calc(50% - 110px);
        }
        .slick-next {
            right: calc(50% - 110px);
        }
    }
}

.slide-item{
    padding: 0 9px;
}


/* Modules */

.section{
    margin-bottom: 48px;
}

.section-title{
    @include bg-white(30px, 20px);
    margin-bottom: 18px;
    img{
        height: 30px;
    }
}

.section-title-has-link{
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    background-color: #fff;

    .section-title-text{
        padding: 35px 32px;
        line-height: 1;
    }

    .section-title-link{
        display: flex;
        width: 140px;
        height: 140px;
        @include bg-pattern();
        border-left: 1px solid #ececec;
        font-size: 11px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .arrow{
        margin-left: -12px;
        &::before{
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            border-top: 1px solid #989898;
            border-left: 1px solid #989898;
            transform: rotate(135deg);
            margin-bottom: 20px;
        }
    }

    img{
        height: 70px;
    }
}

// SP Only
@include media-breakpoint-down(md){
    .main-content:not(.only-wide){
        .section-title-has-link{
            margin-bottom: 12px;

            .arrow::before{
                width: 14px;
                height: 14px;
                margin-bottom: 6px;
            }

            p{
                font-size: 10px;
                transform: scale(0.8);
                transform-origin: center top;
            }
        }

        .section-title-text{
            padding: 17px 16px;

            img{
                height: 30px;
                max-width: 100%;
            }
        }

        .section-title-link{
            width: 68px;
            height: 68px;
            min-width: 68px;

            .box-icon{
                margin-top: 8px;
            }
        }
    }
}

.section-no-item{
    .icon-no-item{
        position: absolute;
        width: calc(252px + #{$sidebar-w});
        height: 132px;
        text-align: center;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding-left: $sidebar-w;

        figcaption{
            margin-top: 24px;
            font-size: 18px;
            font-weight: 500;
            line-height: 1;
        }

        img{
            width: 96px;
            height: 96px;
        }
    }
}

// SP Only
@include media-breakpoint-down(md){
    .main-content:not(.only-wide){
        .icon-no-item{
            padding-left: 0;
        }
    }
}

.section-card-list{
    margin-bottom: 12px;

    @include media-breakpoint-down(md){
        margin-bottom: 6px;
    }
}

.btn-icon-left{
    position: relative;
    font-size: 13px;
    padding: 14px 20px;
    border-radius: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: none;

    .icon{
        height: 18px;
    }
    
    .icon:first-child{
        margin-left: 0;
        position: absolute;
        left: 20px;
    }

    &:hover{
        color: rgba(0, 0, 0, 0.6);
        .icon{
            opacity: 0.6;
        }
    }

    &.btn-primary{
        &:hover{
            background-color: rgba(0, 0, 0, 0.7);
            color: #FFF;
            //border-color: #000;
        }
    }
}

.btn-list{
    margin-bottom: 0;
    li{
        text-align: center;
        margin-bottom: 12px;
        &:last-child{
            margin-bottom: 0;
        }
    }

    .btn{
        max-width: 320px;
        width: 100%;
        margin: 0 auto;
    }
    

    @include media-breakpoint-up(lg){
        li{
            padding-right: 40px;
            padding-left: 40px;
            min-width: 400px;
        }
    }
}

.floating{
    width: 88px;
    height: 88px;
    position: fixed;
    right: 18px;
    bottom: 18px;
    box-shadow: 0px 2px 3.92px 0.08px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 10px;
    z-index: 50;

    .icon{
        margin-bottom: 12px;
    }

    @include media-breakpoint-down(md){
        display: none;
    }
}

.form-section{
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;
    
    &:last-child{
        border: none;
    }
}

.form-control{
    transition: background-color 300ms;
    &.has-icon{
        padding: 0 0 0 42px;
    }
}

.form-lavel-thumbnail{
    input{
        display: none;
    }

    .lavel-thumbnail{
        border: 1px solid #cfcfcf;
        cursor: pointer;
        margin-bottom: 18px;
        &:last-child{
            margin-bottom: 0;
        }

        img{
            width: 100%;
        }
    }

    input:checked ~ .lavel-thumbnail{
        border-color: #808080;
    }
}

.pointer{
    cursor: pointer;
}

.blocklink::before{
    content:"";
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.bg-pattern{
    @include bg-pattern()
}

.bg-gray{
    background-color: $hover-gray;
}

.title{
    &.has-bullet{
        font-weight: 500;
        &::before{
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 3px 0 3px;
            border-color: #333333 transparent transparent transparent;
            margin-right: 11px;
        }
    }
}

.nav-filter{
    height: 48px;

    .nav-link{
        padding: 17px 20px;
        line-height: 1;
        border-right: 1px solid $border-gray;

        &.active, &:hover{
            background-color: #000;
            color: #FFF;

            .text-counter{
                color: #FFF;
            }
        }
    }

    .text-counter{
        display: inline-block;
        margin-left: 9px;
        color: #999;
    }
}

.table{
    &.has-checkbox{
        th, td{
            &:first-child{
                padding: 18px 0;
                width: 56px;
                text-align: center;
            }

            // MARK: 
            // チェック欄のクリック領域が狭く、チェックしたつもりが実績詳細に飛んでしまうので、
            // 赤枠くらい（周囲の余白分）まで、クリック領域を広げていただけますでしょうか。
            .blocklink::before{
                left: 56px;
            }
            .form-check {
                .form-check-input  {
                    width: 56px;
                    height: 56px;
                    top: -21px;
                    left: -16px;
                }
            }
        }
    }

    &.has-blocklink{
        tr{
            position: relative;
            display: table;
            width: 100%;
        }

        .btn, .in-blocklink{
            position: relative;
            z-index: 20;
        }
    }
}

.row-wide-gutter{
    margin-right: -15px;
    margin-left: -15px;

    & > *[class^="col"]{
        padding-right: 15px;
        padding-left: 15px;
    }
}

.row-narrow-gutter{
    margin-right: -3px;
    margin-left: -3px;

    & > *[class^="col"]{
        padding-right: 3px;
        padding-left: 3px;
    }
}

.media-upload{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 160px;
    border: solid 1px #d6d6d6;
    background-color: #f5f5f5;
    padding: 0 !important;
    border-radius: 0 !important;
    position: relative;

    &.is-lg{
        height: 248px;
    }

    .box-has-icon{
        text-align: center;
        color: #333;
        padding: 15% 1px;
    }

    .icon{
        margin-bottom: 14px;
    }

    .box-button{
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 10;
        display: none;
    }

    &:hover .box-button{
        display: block;
    }

    .btn-border:not(:hover){
        background-color: #fff;
    }

    p{
        display: block;
        font-size: 12px;

        &.file-name{
            font-size: 18px;
        }
    }

    img.uploaded{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.disabled .media-upload .box-button{
    display: none;
}

.main-content:not(.only-wide){
    .px-sp{
        @include media-breakpoint-down(md){
            padding-right: 16px !important;
            padding-left: 16px !important;
        }
    }
    .py-sp{
        @include media-breakpoint-down(md){
            padding-top: 16px !important;
            padding-bottom: 16px !important;
        }
    }
}

.mb-2-5{
    margin-bottom: 12px !important;
}
.mr-2-5{
    margin-right: 12px !important;
}
.ml-2-5{
    margin-left: 12px !important;
}

.link-underline{
    display: inline;
    padding-bottom: 2px;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    border-bottom: 2px solid $text-black;
    word-break: break-all;

    &:hover{
        border-bottom-color: rgba(36, 36, 36, 0.6);
    }
}

.tag{
    letter-spacing: 1px;
    &::before{
        content: "#";
    }
}

// SP Only
@include media-breakpoint-down(md){
    .main-content:not(.only-wide){
        .tag{
            font-size: 10px;

            &.btn-border{
                height: 32px;
            }
        }

        .link-underline{
            border-bottom-width: 1px;
        }
    
    }
}

.loading-overlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;

    img{
        width: 80px;
        height: 80px;
    }
}

.has-nav-search{
    @include media-breakpoint-down(md){
        margin-top: calc(48px + 3px);
    }
}

.form-group-search{
    position: relative;
    .icon-search{
        position: absolute;
        z-index: 2;
        display: block;
        top: 50%;
        left: 12px;
        width: 18px;
        height: 18px;
        text-align: center;
        margin-top: -9px;            
    }

    .form-control{
        height: 45px;
    }
}

/* Sidebar Setting */

.sidebar{
    text-align: center;
    width: $sidebar-w;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1030;
    background-color: #000;
    color: #FFF;
    overflow-y: auto;
    transition: transform 200ms;

    .logo{
        margin-bottom: 45px;
        img{
            width: 56px;
            height: 38.08px;
        }
    }
    a{
        color: #FFF;
    }

    &:not(.only-wide){
        @include media-breakpoint-down(md){
            width: 50%;
            max-width: 187.5px;
            transform: translatex(-187.5px);

            &.show{
                transform: translatex(0);
                & + .main-content::before{
                    position: fixed;
                    opacity: 1;
                    z-index: 100;
                }
            }

            .logo{
                display: none;
            }

            .navbar-toggler-close{
                display: block;
            }

            .sidebar-wrapper{
                min-height: 520px;
                padding: 13px 0 13px 20px;
            }

            .sidebar-nav-item{
                margin-bottom: 23px;
        
                & > a{
                    display: flex;
                    align-items: center;
                }
                .icon{
                    margin-bottom: 0;
                    width: 20px;
                    text-align: center;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .text{
                    font-size: 12px;
                    display: block;
                    margin-left: 14px;
                    font-weight: bold;
                }
                &:nth-of-type(5){
                    display: none;
                }
            }

            .sidebar-nav-bottom{
                width: calc(100% - 40px);
                padding-bottom: 30px;
            }
        }
        
    }

    .navbar-toggler-close{
        display: none;
        margin-bottom: 43px;
    }
}

.sidebar-wrapper{
    padding: 25px 0;
    height: 100%;
    min-height: 680px;
    position: relative;
}

.sidebar-nav-item{
    margin-bottom: 28px;
    font-size: 10.5px;
    a, button{
        display: block;
        font-weight: bold;
        cursor: pointer;
    }

    &.active, a:hover{
        opacity: 0.7;
        .icon{
            opacity: 1;
        }
    }
    &:last-child{
        margin-bottom: 0;
    }
    .icon{
        display: block;
        margin-bottom: 10px;

        img{
            height: 22px;
        }
    }
}
.sidebar-nav-bottom{
    background-color: #000;
    position: absolute;
    padding-bottom: 48px;
    width: 100%;
    bottom: 0;
    padding-top: 18px;
}

// Card - List Item

.card-list-item{
    height: calc(100% - 36px);
    margin-bottom: 36px;
    position: relative;
    
    .card-title{
        font-size: 15px;
        margin-bottom: 12px;
        font-weight: 500;
        transition: all 300ms;
        line-height: 1.57;
        @include lhCrop(1.57)
    }

    .card-img-top{
        position: relative;
        padding-top: 56.2%;
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 0;
            transition: all 300ms;
        }

        img, span.lazy-load {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .card-body{
        padding: 30px;
    }

    .card-footer{
        border-top: 1px solid #ececec;
        padding: 24px 30px 20px;
    }

    .small{
        font-size: 12px;
    }

    .tag{
        font-size: 10px;
        padding: 10px 12px;
    }

    .btn-delete{
        width: 30px;
        height: 30px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 20;
        border: 1px solid #666;

        .icon{
            width: 10px;
            height: 10px;
        }

        &:hover{
            .icon{
                opacity: 0.6;
            }
        }

        @include media-breakpoint-down(md){
            width: 32px;
            height: 32px;

            .icon{
                width: 14px;
                height: 14px;
                &.icon-close-delete{
                    background: url("../images/icon_close.svg") left top no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    .btn-primary.btn-block{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .btn-lined-white{
        position: absolute;
        width: 120px;
        height: 32px;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 300ms;
        z-index: 20;
    }

    &:not(.is-no-link):hover{
        .card-title{
            opacity: 0.6;
        }
        .card-img-top{
            img{
                transform: scale(1.1);
            }

            &::after{
                background-color: rgba(0, 0, 0, 0.5);
            }

        }

        .btn-lined-white{
            opacity: 1;
        }
    }

    &.is-small{
        .card-body{
            padding: 24px 20px;
        }

        .card-footer{
            padding: 16px 20px;
        }

        .card-text{
            font-size: 12px;
        }
    }

    &:not(.is-small){
        @include media-breakpoint-down(md){
            margin-bottom: 24px;

            .card-title{
                font-size: 14px;
                margin-bottom: 10px;
            }

            .card-body{
                padding: 23px 16px;
            }

            .card-footer{
                padding: 15px;
            }
        }
    }
}

/* Navbar Setting */

.navbar-header{
    margin-bottom: 48px;
    align-items: stretch;
    z-index: 100;

    &:not(.only-wide){
        @include media-breakpoint-down(md){
            margin-right: - $content-sm-pd-x;
            margin-left: - $content-sm-pd-x;
            position: fixed;
            width: 100%;
            z-index: 60;
            top: 0;
            background-color: #000;
            
            .navbar-brand{
                width: 41px;
                top: 8px;
                padding: 0;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                img{
                    height: 27px;
                }
            }

            .navbar-toggler{
                height: $navbar-sm-h;
            }

            .navbar-break{
                flex-basis: 100%;
                order: 3;

                .nav-item{
                    flex: 1 1 auto;
                    min-width: auto;
                }
            }

            .navbar-nav{
                height: 48px;
                & > .dropdown-toggle{
                    padding: 17px 18px 17px 20px;
                }    
            }
        }
    }
}

.navbar-break{
    @include bg-pattern();
    flex-grow: 1;
    align-items: center;

    @include media-breakpoint-up(lg){
        border-right: 1px solid #e9e9e9;
    }

    .nav-item{
        min-width: 240px;

        &:nth-child(n+2):not(.active){
            display: none;
        }    
    }
    
    &:not(.active){
        display: none;
    }

    .dropdown-toggle{
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 100%;
    }

    .dropdown-menu{
        border: none;
        outline: 1px solid #e9e9e9;
    }
}

.navbar-nav{
    flex-direction: row;
    width: 100%;
    height: $navbar-h;

    .nav-item{
        line-height: 1;
        padding: 17px 23px 17px 20px;
        border-right: 1px solid $border-gray;
        position: relative;
        
        &.dropdown{
            padding: 0;
            display: block;
            z-index: 10;
        }

        & > .icon:first-child{
            margin-right: 10px;
        }

        &.only-icon{
            width: $navbar-h;
            min-width: $navbar-h;
            justify-content: center;
            padding-right: 0;
            padding-left: 0;
            transition: background-color 200ms;

            & > .icon{
                margin-right: 0;
                margin-bottom: 4px;
            }
    
        }

        &.ml-auto{
            border-right: none;
        }
    }

    button.nav-item, a.nav-item{
        &:focus, &:active, &:not(:disabled):not(.disabled):active:focus{
            box-shadow: none;
        }
    }

    & > a.nav-item{
        overflow: hidden;
        &:not(.btn-primary).active, &:not(.btn-primary):hover{
            text-decoration: none;
            color: $text-black;
            @include bg-pattern();
            &::after{
                width: 100%;
            }
            .icon{
                opacity: 1;
            }
        }

        &::after{
            content: "";
            display: block;
            height: 2px;
            background-color: #000;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            transition: all 200ms;
        }
    }

    a.navbar-item:hover{
        background-color: #fff;
    }

    .dropdown.show{
        background-color: #fff;
    }

    .dropdown-menu{
        position: absolute;
        padding: 13px 0;
    }

    & > .dropdown-toggle{
        border-radius: 0;
        display: inline-flex;
        align-items: center;
        padding-right: 18px;
        height: 100%;    
        padding: 23px 18px 23px 20px;
    }
}

.nav-item{
    display: flex;
    align-items: center;
    height: 100%;
}

.navbar-toggler{
    padding: 0;
    margin-left: 16px;
    border: none;
    &:focus{
        outline: none;
    }
    .bar{
        width: 28px;
        display: block;
        height: 1px;
        background-color: #FFF;
        margin-bottom: 5px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.navbar-admin{
    .nav-item{
        font-weight: 500;
        &:first-child{
            font-weight: bold;
        }
    }
}

/* Search Navbar Setting */

.nav-search{
    width: 336px;
    .form-group, .form-control{
        position: relative;
    }

    .dropdown-body{
        background-color: #fff;
        height: $navbar-h;
        align-items: center;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;

        .icon-search{
            left: 0;
        }
    }

    .dropdown-body .form-control.has-icon{
        padding-left: 30px;
        border: none;
    }

    .dropdown-menu{
        padding: 0;
    }

    .title{
        line-height: 1;
        font-size: 13px;
        i{
            margin-right: 6px;
            height: 16px;
            img{
                width: 11px;
                margin-top: -0.25em;
            }
        }
        &.has-bullet{
            margin-left: 3px;
            margin-bottom: 0;
        }
    }
    a{
        font-size: 12px;
        font-weight: 500;
    }

    .form-check{
        padding-left: 20px;
        margin-top: 20px;
        margin-bottom: 0;
        label{
            font-size: 12px;
        }
        .form-check-input{
            & + label::before{
                width: 13px;
                height: 13px;
                margin-left: -20px;
            }
            & + label::after{
                left: -20px;
            }
        }
    }

    @include media-breakpoint-down(md){
        position: static;
        width: auto;
        .dropdown-body{
            background-color: transparent;
            border: none;
            left: 0;
            height: 100%;

            .icon-search{
                padding: 14px 16px;

                &::before{
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    background: url("../images/icon_search_white.svg") left top no-repeat;
                    background-size: cover;
                }
            }
    
        }

        .dropdown-toggle{
            position: relative;
            z-index: 5;
            &::after{
                display: none;
            }
        }

        .icon-close{
            display: none;
            margin-right: 18px;
        }

        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            background-color: transparent;
            z-index: 1;
            transition: opacity 200ms;
        }


        &.show{
            &::after{
                width: 100%;
                height: 100vh;
                opacity: 1;
                background-color: rgba(0, 0, 0, 0.7);
            }

            .icon-close{
                display: inline-block;
            }
            .dropdown-body .icon-search{
                display: none;
            }
        }
    }
}

/* Main Content Setting */

.main-content{
    padding-left: 36px;
    padding-right: 36px;
    margin-left: $sidebar-w;
    min-width: calc(#{$content-min-w} + 72px);

    &::before{
        content: "";
        display: block;
        position: relative;
        z-index: 1;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.8);
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        transition: opacity 200ms;
    }

    &:not(.only-wide){
        @include media-breakpoint-down(md){
            min-width: auto;
            max-width: none;
            padding-left: $content-sm-pd-x;
            padding-right: $content-sm-pd-x;
            padding-top: calc(#{$navbar-sm-h} + 30px);
            margin-left: 0;
        }

        .main-container{
            @include media-breakpoint-down(md){
                min-width: auto;
                max-width: none;
            }
        }
    }

    &.no-sidebar{
        margin: 0 auto;
        min-width: calc(884px + 72px);
        max-width: calc(1304px + 72px);
    }
}

.main-container{
    min-width: $content-min-w;
    max-width: $content-max-w;
    margin-right: auto;
    margin-left: auto;
}

.no-sidebar .main-container{
    min-width: 884px;
    max-width: 1304px;
}

/* Home Page Setting */

.pickup{

    .card{
        height: 100%;
    }

    .card-list-item{
        height: 100%;
        margin-bottom: 0;
        display: block !important;

        .card-title{
            font-size: 21px;
            font-weight: normal;
            line-height: 1.38;
            margin-top: 4px;
        }    
    }

    @include media-breakpoint-up(lg){
        .card-list-item .card-title{
            margin-top: 53px;
            font-size: 28px;
            margin-bottom: 15px;
            line-height: 1.39;
        }

        .pickup-info{
            max-width: 434px;
            width: 45%;
            order: 1;

            .small{
                font-size: 16px;
            }
        }

        .pickup-image{
            min-height: 434px;
            flex: 2;
            order: 2;
            padding-top: 0;
        }
    }

    @include media-breakpoint-down(md){
        .pickup-label{
            padding: 9px 15px;
            img{
                height: 15px;
            }
        }

        .card-body{
            padding: 25px 16px;
        }
    }
}

.pickup-label{
    display: inline-flex;
    align-items: center;
    background-color: #000;
    padding: 12px 30px;
    line-height: 1;
    z-index: 10;

    img{
        height: 18px;
    }
}

.pickup-info{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.nav-category{
    &-title{
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-size: 17px;
        padding: 16px 30px;
        @include bg-pattern();
    }

    &-list{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -1px;
        font-size: 10px;
        a{
            background-color: #fff;
            display: block;
            padding: 12px 20px;
            border-bottom: 1px solid $body-bg;
            border-left: 1px solid $body-bg;
            height: 100%;
            line-height: 1;
            display: inline-flex;
            align-items: center;
            font-weight: 500;

            &:hover{
                background-color: #000;
                color: #FFF;
            }
        }
    }

    @include media-breakpoint-down(md){
        .nav-category-title{
            padding: 18px 16px;
        }

        .nav-category-list a{
            padding: 10px 15px;
        }
    }
}

/* Works Detail Setting */

.works-detail-main{
    flex: 1;
}

.works-detail-body{
    margin-bottom: 36px;
}

.works-detail-nav{
    @include bg-white(14px, 30px);
    width: 88px;
    text-align: center;
    z-index: 1;
}

.works-detail-header{
    display: flex;
    border-bottom: 1px solid #ececec;
}

.works-detail-title{   
    flex: 2;

    .title{
        font-weight: normal;
        margin-bottom: 1rem;
        line-height: 1.4;
        @include lhCrop(1.4);
    }
    
    .subtitle{
        font-weight: 300;
        font-size: 1.285rem;
        margin-bottom: 0;
    }
}

.works-visual-main{
    position: relative;
    width: 100%;
    padding-top: 56.27%;
    max-width: 1244px;
    margin: 0 auto 30px;

    .works-visual-main-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    img{
        width: auto;
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
        object-fit: contain;
    }
}

.works-detail-info{
    border-left: 1px solid #ececec;
    width: 360px;
    max-width: 360px;
    flex: 1 1 360px;
    line-height: 1.4;
    @include lhCrop(1.4);
    p{
        margin-bottom: 10px;
    }

    .text-muted{
        display: inline-block;
        padding-right: 7px;
    }
}

.works-detail-thumbnails{
    max-width: 810px;

    .col{
        text-align: center;
        max-width: 210px;
    }

    .thumbnail{
        &:hover{
            opacity: 0.7;
        }
    }
}

.thumbnail{ // 9by5
    width: 100%;
    display: block;
    position: relative;
    padding-top: 55%;
    margin: 0 auto;

    &::after{
        content: "";
        display: block;
        height: 100%;
        background-color: transparent;
    }

    img, &::after, &::before{
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.active::after{
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }

    &.video::before{
        content: "";
        width: 41px;
        height: 41px;
        background-color: #fff;
        background: url("../images/icon_play.svg") center top no-repeat;
        background-size: contain;
        z-index: 10;
        margin: auto;
        opacity: 0.6;
    }
}

.works-detail-items{
    display: flex;
    background-image: linear-gradient(#ececec 0, #ececec 100%);
    background-size: 1px 1px;
    background-repeat: repeat-y;
    background-position: center top;
}

.works-detail-item{
    position: relative;
    padding: 30px 30px 50px;
    &:not(:last-child){
        border-bottom: 1px solid #ececec;
    }
}

.works-detail-item-title{
    margin-top: 17px;
    margin-bottom: 34px;
}

.works-detail-item-body{
    line-height: 1.86;
}

/* SP only */
@include media-breakpoint-down(md){
    .main-content:not(.only-wide){

        .section-works-detail{
            margin-top: -16px;

            .btn-icon-left:not(:last-child){
                margin-bottom: 16px;
            }
        }

        .works-detail-body{
            margin-bottom: 16px;
        }

        .works-detail-header{
            display: block;
        }

        .works-detail-title{
            border-bottom: 1px solid #ececec;

            .title{
                font-size: 20px;
            }

            .subtitle{
                font-size: 16px;
            }
        }

        .works-detail-info{
            border-left: none;
            width: auto;
        }

        .works-visual-main{
            margin-bottom: 16px;
        }

        .works-detail-thumbnails{
            .thumbnail.video::before{
                width: 50%;
                height: 50%;
            }
            .col:first-child:last-child .thumbnail{
                width: 140px;
                padding-top: 40%;
            }    
        }


        .works-detail-items{
            display: block;
            background: none;

            .col:not(:first-child){
                border-top: 1px solid #ececec;
            }
        }

        .works-detail-item{
            padding: 16px 16px 30px;
        }

        .works-detail-item-title{
            margin-bottom: 16px;
            margin-top: 10px;
            img{
                height: 27px;
            }
        }

        .works-detail-thumbnails{
            .row-wide-gutter{
                margin-right: -5px;
                margin-left: -5px;
            }

            .row-wide-gutter > *[class^="col"] {
                padding-right: 5px;
                padding-left: 5px;
            }
        }
    }
}

/* Works Edit Setting */

.works-edit-main{
    flex: 1;
    margin-right: 14px;

    .textarea-large{
        min-height: 200px;
    }
}

.works-edit-nav{
    width: 220px;
    position: sticky;
    top: 20px;
}


/* Document Edit setting */

.navbar-document{
    padding: 0;
}

.nav-document{
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 14px;
    padding-right: 14px;

    .btn-group-even{
        max-width: 205px;
        min-width: 186px;
        margin-left: 12px;
    }
}

.navbar-document + .navbar-header{
    display: none;
}

.popover-nav-left{
    width: 230px;
    top: 52px;
    border-radius: 0;
    border: none;
    box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.04);
    z-index: 1000;

    .arrow{
        left: 19px;
    }

    .popover-body{
        padding: 20px;
    }

    &:not(.show){
        display: none;
    }
}

textarea.form-control.input-memo{
    height: 116px;
}

.doc-title{
    flex: 2;

    input.form-control{
        max-width: 794px;
        min-width: 374px;    
    }
}

.nav-doc-edit{
    display: flex;
}

.document-display{
    display: flex;
    position: relative;

    .icon.badge{
        width: 31px;
        height: 31px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        z-index: 10;
        right: -15px;
        top: -15px;
        transition: opacity 200ms;
        opacity: 1;

        img{
            width: 15px;
            height: 15px;
        }

        &:hover{
            opacity: 0.7;
        }
    }
}

.document-display-nav{
    width: 166px;
    order: 1;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-right: 18px;
    @include hideScrollBar();
}

/* Document Rate Setting */

.document-display-main{
    display: flex;
    justify-content: center;
}

.document-display-overlay {
    z-index: 2000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity 400ms;
}

/* End Document Rate Setting */

.document-display-main{
    order: 2;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    @include hideScrollBar();
}

.document-display-nav,
.document-display-main{
    height: calc(100vh - (48px + 48px + #{$navbar-h}));
}

.document-page-nav{
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFF;
    display: flex;
    font-size: 12px;
    padding: 18px 20px;
    position: fixed;
    line-height: 1;
    bottom: 0;
    right: 0;

    .arrow{
        transform: translateY(4px);
        padding: 0 2px;
        &::before{
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 1px solid #FFF;
            border-left: 1px solid #FFF;
            transform: rotate(45deg);
        }

        &.arrow-next{
            transform: translateY(-3px);
            &::before{
                transform: rotate(-135deg);
            }
        }        
    }

    a:hover{
        opacity: 0.6;
    }

    .page-number{
        margin-left: 20px;
        margin-right: 20px;
    }

    .nav-cancel{
        display: none;
        color: #FFF;
    }
}

.presentation + .document-page-nav .nav-cancel{
    display: inline-block;
}

.doc-list{
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
    height: 100%;
    overflow-y: auto;

    li{
        display: flex;
        padding: 6px 12px 6px 10px;
        position: relative;
        justify-content: flex-end;
    }

    li:before {
        display: block;
        counter-increment: item;
        content: counter(item);
        padding-right: 5px;
        font-family: 'Arial','Trebuchet MS','Avenir Next Condensed',sans-serif;
        color: #666666;
    }

    .thubmnail{
        width: 114px;
        height: 80.62px;
        border: 1px solid #e1e1e1;
        background-color: #fff;
        overflow: hidden;
        position: relative;
        transition: all 200ms;

        & > img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 200ms;
        }

        &:hover{
            border-color: #000;

            & > img {
                opacity: 0.6;
            }
        }
    }

    .icon{
        position: absolute;
        top: -5px;
        z-index: 10;
    }

    .thubmnail.blank{
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            display: inline-block;
            width: 28px;
            height: 28px;
        }
    }

    .active{
        @include bg-pattern();

        .thubmnail{
            border-color: #000;
        }
    }

    .icon-slide-add{
        right: calc(50% + -7px);
    }

    .icon-slide-delete{
        right: -5px;
    }

    .icon:hover{
        opacity: 0.7;
    }

}

_:lang(x)+_:-webkit-full-screen-document, .doc-list::-webkit-scrollbar {
    display:none;
}

.document-scale-wrapper{
    position: absolute;
}

.document-display-wrapper{
    position: relative;
    width: 100%;
    padding-top: 70.7174%;
    min-width: 1366px;
    transition: transform 400ms;
    transform-origin: center top;

    .doc-works-slide{
        top: 0;
        left: 0;
    }
}

/* Presentation View */
.document-display.presentation{
    position: absolute;
    top: 0;
    left: 0;
    .document-display-main{
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        display: block;
        overflow: hidden;
    }

    .document-scale-wrapper{
        width: 1366px;
        position: relative;
        left: 50%;
        margin: auto;
        margin-left: calc(-1366px / 2);
    }

    .document-display-wrapper{
        z-index: 2001;
        margin: auto;
        transform-origin: center top;
        margin-bottom: 0;
    }

    & + .document-page-nav{
        z-index: 2003;
    }
}

.doc-works-slide{
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #fff;
    overflow: hidden;

    .visual{
        background-color: #c8c8c8;
        margin-bottom: 0;
        display: block;
        overflow: hidden;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }    
    }

}

.nombre {
    position: absolute;
    bottom: 26px;
    right: 30px;
    text-align: right;
    color: #666666;
    font-size: 16px;
    font-family: Arial, sans-serif;
}

.doc-works-empty + .nombre{
    bottom: 26px;
    right: 30px;
}

.doc-works-empty{
    .icon{
        width: 180px;
        height: 180px;
    }
}

/* SP only */
@include media-breakpoint-down(md){
    .main-content:not(.only-wide){    
        .navbar-document{
            margin-bottom: 24px;

            .navbar-nav{
                height: auto;
            }
        }

        .navbar-document + .navbar-header{
            display: flex;
        }

        .navbar-header.navbar-document{
            padding: 16px;
            height: auto;
            position: static;
            margin-top: -32px;
            width: calc(100% + 32px);
        }

        .nav-document{
            display: block;
            padding: 0;
        }

        .doc-title{
            margin-right: 0 !important;

            h6{
                margin-bottom: 10px;
            }
        }

        .doc-date{
            font-size: 12px;
            line-height: 1;
        }

        .document-display:not(.presentation){
            display: block;

            .document-display-nav,
            .document-display-main{
                height: auto;
            }
    
            .document-display-main{
                min-width: auto;
                max-width: none;
                margin-bottom: calc(100px + 40px + 16px);
                position: relative;
            }
    
            .document-display-nav{
                position: fixed;
                bottom: 0;
                width: 100%;
                margin-right: -16px;
                margin-left: -16px;
                padding: 0 10px;
                margin-right: 18px;
                height: 100px;
            }
    
            .doc-list{
                display: flex;
            }
    
            .doc-list li{
                flex-direction: column-reverse;
                padding: 12px 6px 0;
            }
    
            .doc-list .thubmnail{
                width: 65px;
                height: 45.97px;
            }
    
            .doc-works-slide{
                position: relative;
                padding-top: 70.7174%;
                min-width: 1366px;
                margin-bottom: 96px;
            }
    
            & + .document-page-nav{
                position: fixed;
                bottom: 100px;
                width: 100%;
                justify-content: space-between;
                padding: 14px 89px;
            }

            .doc-works-body {
                position: absolute;
                min-height: 100%;
                width: 100%;
                top: 0;
                left: 0;
            }

            .document-scale-wrapper{
                position: static;
                overflow: hidden;
            }

            .document-display-wrapper{
                transform-origin: left top;
                padding-top: 0;
                background: none;
            }

            .page-number{
                margin-right: 62px;
                margin-left: 62px;
            }

        }

        .arrow{
            height: auto;
        }
    }
}

/* Company Slide Setting */

.company-body{
    padding: 82px 82px 0;
    color: #333333;
}

.company-subtitle{
    background-color: #000;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.6px;
    text-align: center;
    color: #ffffff;
    padding: 12px;
    margin-bottom: 46px;
}

.company-ol{
    dt{
        display: block;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 42px;
        font-weight: bold;
        line-height: 0.95;
        letter-spacing: 2.1px;
        margin-right: 20px;
    }

    dd{
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6;
        letter-spacing: 0.6px;
        margin-bottom: 0;
        margin-top: 2px;
    }
}

.company-ol-wrapper{
    display: flex;
    margin-bottom: 17px;
}

.company-picture-dark{
    display: none;
}

.company-1{
    .company-picture{
        padding-bottom: 118px;
        padding-top: 35px;
        margin-bottom: 0;
    }

    p{
        font-size: 22px;
        font-weight: 700;
        line-height: 2;
        letter-spacing: 1.1px;
        margin-bottom: 55px;
        @include lhCrop(2);

        &:last-of-type{
            letter-spacing: 1.2px;
        }
    }
}

.company-2{
    .company-ol{
        padding-left: 171px;
    }

    .company-ol-wrapper:nth-of-type(2){
        margin-bottom: 36px;
    }

    .company-ol-wrapper:last-child{
        align-items: center;

        dd{
            line-height: 2;
            margin-top: -2px;
        }
    }

    .company-picture{
        padding-top: 33px;
        margin-left: 10px;
    }

    p.small{
        padding-left: 171px;
        font-size: 13px;
        line-height: 2;
        padding-top: 15px;
    }
}

.company-3{
    p{
        font-size: 20px;
        line-height: 2;
        letter-spacing: 0.6px;
        margin-bottom: 0;
        @include lhCrop(2);
    }

    hr{
        background-color: #7f7f7f;
        margin-top: 43px;
        margin-bottom: 92px;
    }

    .company-ol{
        padding-left: 64px;
    }

    .company-ol-wrapper{
        margin-bottom: 46px;
    }

}

/* Document Layout Simple */

/** Common Setting **/

.design-simple-light{
    --simple-txt-c: #000;
    --simple-bg-c: #FFF;
    --simple-headding-c: #FFF;
    --simple-headding-bg-c: #000;
    --simple-result-icon: url("../images/icon_result.svg");
    --simple-company-c: #333;
}

.design-simple-dark{
    --simple-txt-c: #FFF;
    --simple-bg-c: #000;
    --simple-headding-c: #333;
    --simple-headding-bg-c: #FFF;
    --simple-result-icon: url("../images/icon_result_dark.svg");
    --simple-company-c: #FFF;

    .design-simple-header-left{
        border-top: none !important;
    }

    .company-picture-light{
        display: none;
    }
    .company-picture-dark{
        display: inline-block;
    }

    .company-header{
        border-bottom: 1px solid #727272;
    }
}

.design-simple{

    .editable{
        min-width: 100%;
    }

    .doc-works-slide{
        font-size: 14px;
        color: var(--simple-txt-c);
        background-color: var(--simple-bg-c);    
    }

    .nombre{
        color: var(--simple-txt-c);
    }

    .design-simple-header{
        display: flex;
        height: 72px;
        overflow: hidden;

        &-left{
            border-top: 1px solid #7f7f7f;
            border-bottom: 1px solid #7f7f7f;
            flex: 2 2 60%;
            display: flex;
            align-items: center;
            padding: 0 25px;
            overflow: hidden;
        }

        &-right{
            flex: 1 1 39%;
            background-color: var(--simple-headding-bg-c);
            color: var(--simple-bg-c);
            padding: 18px 20px 0;
            overflow: hidden;
            max-width: 39%;
        }
    }

    .doc-works-title{
        font-size: 22px; 
        margin-bottom: 0;
        line-height: 1.4;
        @include pHeight(22, 1.4, 1);
    }

    .doc-works-info{
        display: flex;
        margin-bottom: 0;
        line-height: 1;

        dt{
            display: block;
            margin-right: 38px;
            font-family: Arial, sans-serif;
            white-space: nowrap;
            width: 57px;
            min-width: 57px;
            font-weight: 500;
            letter-spacing: 0.7px;
            margin-top: 1px;
            @include pHeight(14, 1, 1);
        }
        
        dd{
            font-size: 13px;
            margin-bottom: 0;
            letter-spacing: 1px;
            flex: 2 2 100%;

            p{
                margin-bottom: 5px;
                line-height: 1.4;
                margin-top: -1px;
                font-weight: 500;
                letter-spacing: 0.65px;
                @include pHeight(13, 1.4, 1);
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }

    .design-simple-body{
        padding: 82px 82px 0;
    }

    .doc-works-dl{
        margin-bottom: 0;
        dt{
            display: block;
            color: var(--simple-headding-c);
            background-color: var(--simple-headding-bg-c);
            padding: 4px 8px;
            margin-bottom: 10px;
            line-height: 1;
            letter-spacing: 1.4px;
        }

        dd{
            line-height: 2;
        }
    }

    .design-simple-result{
        display: flex;
        margin-bottom: 0;

        dt{
            display: flex;
            background-color: var(--simple-headding-bg-c);
            color: var(--simple-headding-c);
            min-width: 282px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            
            .icon-result{
                display: inline-block;
                width: 104px;
                height: 104px;
                background-image: var(--simple-result-icon);
                background-position: left top;
                background-repeat: no-repeat;
            }

            .text{
                display: block;
                font-size: 28px;
                margin-top: 40px;
                line-height: 1;
            }
        }

        dd{
            height: 282px;
            border: 1px solid #7f7f7f;
            border-left: none;
            flex: 1 1 auto;
            margin-bottom: 0;
            font-weight: bold;
            line-height: 2.2;
            font-size: 20px;
            padding: 0 40px;
            display: flex;
            align-items: center;

            .dd-body{
                @include pHeight(20, 2.2, 6);
            }
        }
    }

    .design-simple-col{
        .doc-works-catch{
            font-size: 26px;
            font-weight: bold;
            text-align: justify;
        }
        .doc-works-dl dd{
            white-space: nowrap;
        }
    }

    /** Layout Setting **/

    .layout-image{

        .design-simple-keyvisual{
            width: 100%;
            height: 580px;
        }

        .design-simple-body{
            padding-top: 52px;

            p{
                font-size: 16px;
                @include pHeight(16, 1.6, 1);
            }    
        }

        .doc-works-catch{
            font-size: 36px;
            font-weight: bold;
            letter-spacing: 1.8px;
            line-height: 1.67;
            @include pHeight(36, 1.67, 2);
            margin-top: -8px;
            margin-bottom: 46px;
        }

    }

    .layout-overview{

        .design-simple-body{
            padding-top: 62px;
        }

        .doc-works-catch{
            margin-top: -7px;
            line-height: 1.5;
            letter-spacing: 1.3px;
            @include pHeight(26, 1.5, 3);
            margin-bottom: 30px;
        }
        
        .visual{
            height: 458px;
            width: 699px;
        }

        .design-simple-col{
            width: 447px;

            .doc-works-dl{
                margin-bottom: 30px;
                
                dd{
                    @include pHeight(14, 2, 1);
                }

                &:last-child{
                    margin-bottom: 0;

                    dd{
                        @include pHeight(14, 2, 4);
                        white-space: normal;
                    }
                }
            }
    
        }

        .design-simple-body > .doc-works-dl:last-child{
            margin-top: 46px;

            dd{
                @include pHeight(14, 2, 8);
            }
        }

    }

    .layout-detail-1{

        .design-simple-body{
            padding-top: 72px;
        }

        .doc-works-catch{
            line-height: 1.62;
            letter-spacing: 2.6px;
            margin-bottom: 90px;
            @include pHeight(26, 1.62, 3);
        }

        .design-simple-visual{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 8px;
            height: 336px;
            margin-bottom: 42px;
        }

        .layout-detail-1-left{
            width: 447px;

            .doc-works-dl dd{
                @include pHeight(14, 2, 1);
            }

            .doc-works-dl:first-child{
                margin-bottom: 35px;
            }
        }

        .layout-detail-1-right{
            max-width: 700px;
            flex: 2;
            margin-top: 10px;

            dd{
                @include pHeight(14, 2, 12);
            }
        }
    }

    .layout-detail-2{

        .doc-works-dl:first-child{
            margin-bottom: 48px;
            dd{
                height: calc(14 * 2 * 3 * 1px);
                overflow: hidden;
            }
        }

        .doc-works-dl:nth-child(2){
            margin-bottom: 84px;
            dd{
                height: calc(14 * 2 * 6 * 1px);
                overflow: hidden;
            }
        }
    }

    .is-company{
        .company-header{
            font-size: 22px;
            color: #FFF;
            font-weight: bold;
            background-color: #000;
            padding: 25px 24px 26px;
            letter-spacing: 1.1px;
            line-height: 1;
        }

        .company-subtitle{
            background-color: var(--simple-headding-bg-c);
            color: var(--simple-headding-c);
        }

        .company-body{
            color: var(--simple-company-c);
        }

        .nombre{
            color: var(--simple-txt-c);
        }
    }

}

/* Document Layout Modern */

/* Fonts */

@font-face {
    font-family: 'NotoSerifJp';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/NotoSerifJP-Regular.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'NotoSerifJp';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/NotoSerifJP-Medium.woff') format('woff');
    font-display: swap;
}

.design-modern{

    /** Common Setting **/

    .editable:empty, .editable.empty{
        display: block;
    }

    .nombre{
        color: #FFF;
        font-size: 14px;
        right: 25px;
        bottom: 21px;
    }

    .design-modern-headding{
        font-size: 29px;
        display: inline;
        color: #FFF;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        letter-spacing: 0.87px;
        font-weight: bold;
        line-height: 1.5;
        background-image: linear-gradient(to bottom, transparent 0%, transparent 18%, #000 18%, #000 95%, transparent 95%);

        div{
            display: inline;
        }

    }

    .doc-works-dl{
        margin-bottom: 0;

        dt{
            color: #FFF;
            font-size: 28px;
            line-height: 1;
            display: inline-block;
            letter-spacing: 0.93px;
            padding: 0 2px;
            background-image: linear-gradient(to bottom, transparent 0%, transparent 8%, #000 8%, #000 95%, transparent 95%);
        }

        dd{
            font-family: 'NotoSerifJp', 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, serif;
            line-height: 2;
            letter-spacing: 0.7px;
            text-align: justify;
            color: #000;
        }

        &.is-inline{
            @include pHeight(15, 1.2, 1);
            display: flex;
            align-items: flex-start;
            margin-bottom: 16px;
            font-weight: bold;

            dt, dd{
                display: block;
                font-size: 14px;
                color: #FFF;
                padding: 0;
                margin: 0;
                line-height: 1.2;
                white-space: nowrap;
                background-color: #000;
                padding: 0 2px 2px;
                max-width: 100%;
            }
            dd{
                font-family: 'NotoSansCJKjp', "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, sans-serif;
                word-break: break-all;
                overflow: hidden;
                &.editable{
                    &:empty, &.empty{
                        min-width: 50%;
                    }
                }
                div{
                    display: inline;
                }        
            }

            &:nth-of-type(1) dt{
                min-width: 63px;
            }
            &:nth-of-type(2) dt{
                min-width: 48px;
            }
            &:nth-of-type(3) dt{
                min-width: 67px;
            }
            &:nth-of-type(4) dt{
                min-width: 48px;
            }
            &:nth-of-type(5) dt{
                min-width: 40px;
            }
        }

    }

    .layout-image{
        .visual{
            width: 100%;
            height: 966px;
        }

        .design-modern-body{
            position: absolute;
            padding: 38px 55px 0;
            width: 100%;

            &.is-top{
                top: 0;
            }

            &.is-bottom{
                bottom: 44px;
            }
        }

        .doc-works-catch{
            @include pHeight(60, 1.5, 6);

            .design-modern-headding{
                color: #000;
                background-image: linear-gradient(to bottom, transparent 0%, transparent 18%, #FFF 18%, #FFF 88%, transparent 88%);
                font-size: 60px;
                font-weight: 700;
            }
        }
        .doc-works-title{
            margin-bottom: 20px;
            @include pHeight(31, 1.6, 1);

            .design-modern-headding{
                font-size: 31px;
                font-weight: 700;
            }
        }

        .doc-works-dl.is-inline{
            max-height: none;
            dt{
                display: none;
            }

            dd{
                font-size: 19px;
                @include pHeight(20, 1.2, 1);
            }
        }

    }

    .layout-overview{
        .visual{
            height: 552px;
        }

        .design-modern-body.is-top{
            position: absolute;
            top: 0;
            width: 100%;
            position: absolute;
            padding: 50px 54px 0;
            overflow: hidden;
        }

        .doc-works-title{
            margin-bottom: 36px;
            font-size: 31px;
            @include pHeight(31, 1.6, 2);
        }
        
        .doc-works-dl.is-inline{
            font-size: 17px;
            @include pHeight(17, 1, 1);
        }

        .design-modern-body.is-bottom{
            padding: 52px 55px 0;

            .doc-works-dl{
                dt{
                    margin-bottom: 22px;
                }

                dd{
                    height: calc(14 * 2 * 9 * 1px);
                    overflow: hidden;
                    column-count: 2;
                    column-gap: 40px;
                    max-width: 824px;
                    column-fill: auto;
                    margin-bottom: 0;

                    & > div{
                        display: inline;
                    }
                }
            }
        }

        .doc-works-catch{
            width: 384px;
            margin-top: -8px;
            @include pHeight(33, 1.48, 5);

            .design-modern-headding{
                background-image: none;
                color: #191919;
                font-size: 33px;
                font-weight: 700;
                line-height: 1.48;
                letter-spacing: 1.65px;
            }
        }      
        
        .layout-overview-left{
            min-width: 0;
            max-width: 824px;
            flex: 2 2 824px;
        }

        .nombre{
            color: #000;
        }
    }

    .layout-detail-1{
        .modern-detail-glid{
            display: grid;
            grid-template-columns: 796px 570px;
            grid-template-rows: 483px 483px;
            gap: 0;
            height: 966px;
        }

        .doc-works-catch{
            margin-bottom: 26px;
            @include pHeight(29, 1.5, 3);
        }

        .doc-works-title{
            margin-bottom: 22px;
            @include pHeight(21, 1.5, 2);

            .design-modern-headding{
                font-size: 21px;
            }
        }

        .visual{
            height: 483px;
            width: 100%;
        }

        .layout-detail-1-right{
            padding: 46px 54px 0;
        }

        .layout-detail-1-left{
            padding: 54px;

            dt{
                margin-bottom: 28px;
            }

            dd{
                @include pHeight(14, 2, 12);
            }
        }
    }
    
    .layout-detail-2{
        color: #FFF;

        .design-modern-body{
            padding: 80px 54px 0;
        }

        .visual{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 966px;
            img{
                object-fit: cover;
            }
            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                background-color: rgba(0, 0, 0, 0.55);
            }
        }

        .design-modern-body{
            position: relative;
        }

        .doc-works-dl{
            margin-bottom: 68px;
            width: 725px;

            dt{
                margin-bottom: 30px;
            }

            dd{
                color: #FFF;
                margin-left: 2px;
            }

            &:first-child dd{
                @include pHeight(14, 2, 3);
            }

            &:nth-child(2) dd{
                @include pHeight(14, 2, 8);
            }

            &:last-child{
                font-size: 19px;
                margin-bottom: 0;

                dt{
                    margin-bottom: 22px;
                }
                dd{
                    @include pHeight(19, 2, 6);
                }
            }

        }    
    }

    .is-company{
        .company-header{
            font-weight: bold;
            letter-spacing: 0.96px;
            font-size: 32px;
            display: inline-block;
            margin-left: 36px;
            margin-top: 30px;
            color: #FFF;
            font-weight: 500;
            background-image: linear-gradient(to bottom, transparent 0%, transparent 14%, #000 14%, #000 90%, transparent 90%);
        }

        .company-body{
            padding-top: 80px;
        }

        .nombre{
            color: #000;
        }
    }
    
}

/* Cover Setting */

.doc-cover{
    background-color: #FFF !important;
    color: #191919 !important;

    .cover-content-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo{
        margin-bottom: 35px;
        display: block;

        img{
            width: 732px;
            height: 96px;
        }    
    }

    .date{
        font-family: Helvetica, Arial, sans-serif;
        font-size: 22px;
        line-height: 1;
        letter-spacing: 1.1px;
        margin-bottom: 0;
    }
}

.cover-a{
    .cover-grid-a{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 191px 191px 200px 191px 191px;
    }

    .cover-thumbnail:nth-last-of-type(1){
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }

    .cover-content-wrapper{
        grid-column: 1 / 5;
    }

    .cover-thumbnail:nth-last-of-type(10){
        grid-column: 3 / 5;
        grid-row: 4 / 6;
    }

    .logo {
        margin-bottom: 22px;
        display: block;
        padding-top: 2px;

        img{
            width: 497px;
            height: 65px;
        }
    }
}

.cover-b{
    .cover-grid-b{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 382px 191px 393px;
    }

    .cover-thumbnail:nth-of-type(1){
        grid-column: 1 / 3;
    }

    .cover-thumbnail:nth-of-type(2){
        grid-column: 3 / 5;
    }

    .cover-content-wrapper{
        grid-column: 1 / 5;
    }
}

.cover-c{
    .cover-grid-c{
        display: grid;
        grid-template-columns: 683px 1fr 1fr;
        grid-template-rows: 386px 194px 386px;
    }
    .cover-content-wrapper{
        grid-row: 1 / 4;
    }

    .cover-thumbnail:nth-of-type(1){
        grid-column: 2 / 4;
    }

    .cover-thumbnail:nth-of-type(4){
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }

    .logo{
        margin-bottom: 20px;

        img{
            width: 497px;
            height: 69px;    
        }
    }
}

.cover-d{
    .visual{
        width: 100%;
        height: 966px;
        position: relative;
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    .cover-content{
        width: 732px;
        height: 150px;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/* PDF Setting */

.pdf-content{
    position: relative;
    .doc-works-slide{
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;
        height: 965px;
        page-break-after: always;
    }
    .doc-works-body {
        margin: 0;
        padding: 0;
        position: relative;
        height: 100%;
        width: 100%;
    }
    .document-display-wrapper {
        transform-origin: left top;
        padding-top: 0;
    }
}

.section-card_transition-group {
    .section-card_transition-item-enter {
        opacity: 0;
    }
    .section-card_transition-item-enter-active {
        opacity: 1;
        transition: opacity 200ms ease-in;
    }
    .section-card_transition-item-exit {
        opacity: 1;
    }
    .section-card_transition-item-exit-active {
        opacity: 0;
        transition: opacity 200ms ease-out;
    }
}

.slideup-before {
    transform: translateY(50px);
}

.slideup-enter {
    transform: translateY(50px);
}

.slideup-enter-active {
    transform: translateY(0);
    transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.slideup-enter-done {
    transform: translateY(0);
    transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.cross-fade-leave {
    opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
    opacity: 0;
    transition: opacity 700ms ease-in;
}
  
.cross-fade-enter {
    opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
    opacity: 1;
    transition: opacity 700ms ease-in;
}

.cross-fade-height {
    transition: height 0.5s ease-in-out;
}

/* Login Page */

.box-login{
    width: 196px;
    height: 223px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .logo{
        margin-bottom: 40px;
    }
    
    .btn{
        letter-spacing: 3px;
    }
}
